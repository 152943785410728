import React, { useEffect } from 'react';
import { Typography, Button, Box } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Switch, Route, useRouteMatch, useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';

import colors from 'colors';

import SideMenu from 'components/sideMenu';
import Header from 'components/header';
import EmptyPage from 'components/emptyPage';
import CreateCampaign from './createCampaign';
import Dashboard from './dashboard';
import { Campaign, CampaignBackend, Team, EApiIssue } from 'types';
import { getPersonalCampaigns, getTeamCampaigns, replaceDraftCampaign, defaultDraftCampaign } from './campaignSlice';
import { User, updateDashboardSelected, updateApiIssue } from 'reducers/userSlice';

import api from 'api';
import BaseHeader from 'components/baseHeader';
import DashboardTitle from 'components/dashboardTitle';
import { getTeamNameById, isTeamValid } from 'features/team/teamUtils';
import { RootState } from 'reducers';
import PageNotFound from 'features/pageNotFound';
import CopyRight from '../../components/copyRight';

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        width: '100%',
        height: '100%',
        overflow: 'auto',
        background: colors.backgroundGrey,
    },
    subcontainer: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflow: 'hidden',
        height: '100vh',
    },
}));

type CampaignsProps = {
    campaigns: CampaignBackend[];
    getPersonalCampaigns: () => void;
    getTeamCampaigns: (teamId: string) => void;
    replaceDraftCampaign: (campaign: Campaign) => void;
    user: User;
    selectedTeamId: string | null;
    teams: Team[];
    updateDashboardSelected: (dashboard: string) => void;
    updateApiIssue: (apiIssue: EApiIssue) => void;
};

// eslint-disable-next-line no-shadow, @typescript-eslint/no-shadow
const Campaigns = ({
    campaigns,
    getPersonalCampaigns,
    getTeamCampaigns,
    replaceDraftCampaign,
    user,
    selectedTeamId,
    teams,
    updateDashboardSelected,
    updateApiIssue,
}: CampaignsProps) => {
    const classes = useStyles();
    const { path } = useRouteMatch();
    const history = useHistory();
    const { teamId } = useParams<{ teamId: string }>();

    useEffect(() => {
        if (teamId && !isTeamValid(teamId, teams)) {
            updateDashboardSelected('personal');
            history.push('/campaigns');
        }

        if (teamId && teams.length > 0 && isTeamValid(teamId, teams)) {
            updateDashboardSelected(teamId);
            getTeamCampaigns(teamId);
        } else {
            updateDashboardSelected('personal');
            getPersonalCampaigns();
        }
    }, [teamId, teams]);

    const handleGetCampaigns = () => {
        selectedTeamId ? getTeamCampaigns(selectedTeamId) : getPersonalCampaigns();
    };
    return (
        <>
            <Typography component="div" className={classes.container}>
                <div style={{ display: 'flex' }}>
                    <div className={classes.subcontainer}>
                        <Switch>
                            <Route exact={true} path={path}>
                                <>
                                    <Header />
                                    <Box display="flex" overflow="auto">
                                        <SideMenu active="campaigns" teamId={selectedTeamId} />
                                        <div style={{ flex: 1, padding: '40px 32px 78px' }}>
                                            <DashboardTitle
                                                title={`${
                                                    selectedTeamId ? getTeamNameById(selectedTeamId, teams) : 'Personal'
                                                } Dashboard`}
                                                subtitle={
                                                    selectedTeamId
                                                        ? 'View and manage team campaigns'
                                                        : 'View and manage personal campaigns'
                                                }
                                                button={
                                                    campaigns && campaigns.length ? (
                                                        <Button
                                                            style={{ height: 56 }}
                                                            onClick={() => {
                                                                replaceDraftCampaign(defaultDraftCampaign);
                                                                api.saveCampaign({
                                                                    id: defaultDraftCampaign.id,
                                                                    name: defaultDraftCampaign.name,
                                                                    assets: defaultDraftCampaign.assets,
                                                                    audiences: defaultDraftCampaign.audiences,
                                                                    workflowUI: JSON.stringify(
                                                                        defaultDraftCampaign.workflow,
                                                                    ),
                                                                    workflowUIDraft: JSON.stringify(
                                                                        defaultDraftCampaign.workflowDraft,
                                                                    ),
                                                                    createdBy: user,
                                                                    teamId: selectedTeamId,
                                                                    state: defaultDraftCampaign.state,
                                                                }).then((id) => {
                                                                    history.push(`/campaigns/update/${id.data}`);
                                                                });
                                                            }}
                                                            variant="contained"
                                                            color="primary"
                                                        >
                                                            Create new campaign
                                                        </Button>
                                                    ) : null
                                                }
                                                isTechPassUser={user.isTechPassUser}
                                            />
                                            {campaigns && !campaigns.length ? (
                                                <EmptyPage
                                                    title="No existing campaigns"
                                                    subtitle="You have not created any campaigns"
                                                    withBackground
                                                >
                                                    <Button
                                                        onClick={() => {
                                                            replaceDraftCampaign(defaultDraftCampaign);
                                                            api.saveCampaign({
                                                                id: defaultDraftCampaign.id,
                                                                name: defaultDraftCampaign.name,
                                                                assets: defaultDraftCampaign.assets,
                                                                audiences: defaultDraftCampaign.audiences,
                                                                workflowUI: JSON.stringify(
                                                                    defaultDraftCampaign.workflow,
                                                                ),
                                                                workflowUIDraft: JSON.stringify(
                                                                    defaultDraftCampaign.workflowDraft,
                                                                ),
                                                                createdBy: user,
                                                                teamId: selectedTeamId,
                                                                state: defaultDraftCampaign.state,
                                                            }).then((id) => {
                                                                history.push(`/campaigns/update/${id.data}`);
                                                            });
                                                        }}
                                                        variant="contained"
                                                        color="primary"
                                                        style={{ marginTop: 24 }}
                                                    >
                                                        Create new campaign
                                                    </Button>
                                                </EmptyPage>
                                            ) : (
                                                <Dashboard
                                                    campaigns={campaigns}
                                                    getCampaigns={handleGetCampaigns}
                                                    replaceDraftCampaign={replaceDraftCampaign}
                                                />
                                            )}
                                        </div>
                                    </Box>
                                </>
                            </Route>
                            <Route path={`${path}/create-new-campaign`}>
                                <BaseHeader
                                    title="Create campaign"
                                    backButton
                                    buttonLink={() => {
                                        history.push('/campaigns');
                                    }}
                                />
                                <CreateCampaign />
                            </Route>
                            <Route exact={true} path={`${path}/update/:id/:feature/:emailAssetId`}>
                                <CreateCampaign />
                            </Route>
                            <Route path={`${path}/update/:id/:feature?`}>
                                <CreateCampaign />
                            </Route>
                            <Route path="*">
                                <PageNotFound />
                            </Route>
                        </Switch>
                    </div>
                </div>
            </Typography>
            <CopyRight placement={'postLogin'} />
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    campaigns: state.campaignSlice.campaigns,
    user: state.userSlice.user,
    selectedTeamId: state.userSlice.dashboardSelected !== 'personal' ? state.userSlice.dashboardSelected : null,
    teams: state.teamSlice.teams,
});

const mapDispatchToProps = {
    getPersonalCampaigns,
    getTeamCampaigns,
    replaceDraftCampaign,
    updateDashboardSelected,
    updateApiIssue,
};

export default connect(mapStateToProps, mapDispatchToProps)(Campaigns);

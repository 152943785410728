import { AppPage, AppComponent } from '../../types';

// Common
export const SUPPORT_EMAIL = 'support@personalise.gov.sg';
export const SUPPORT_EMAIL_MSG_PRE = 'We are unable to process your request due to an error. Contact us at';
export const SUPPORT_EMAIL_MSG_POST = 'for assistance.';
export const SUPPORT_EMAIL_MSG = `${SUPPORT_EMAIL_MSG_PRE} ${SUPPORT_EMAIL} ${SUPPORT_EMAIL_MSG_POST}`;
export const TECHPASS_USER_MSG = 'Please log in with TechPass instead.';

export const WORDPRESS_USER_GUIDE_URL = process.env.REACT_APP_WORDPRESS_USER_GUIDE_URL;

// Campaigns
export const SEND_EMAIL = 'sendEmail';
export const EMAIL_OPENED = 'emailOpened';
export const EMAIL_LINK_CLICKED = 'emailLinkClicked';
export const CHECK_ATTRIBUTE = 'checkAttribute';

export const WAIT = 'wait';
export const SPECIFIC_DAY = 'specificDay';
export const IMMEDIATELY = 'immediately';
export const FOR_A_PERIOD_OF = 'forAPeriodOf';

export const DRAFT = 'draft';
export const LIVE = 'live';

export const SENDER_EMAIL_DOMAIN = `@${process.env.REACT_APP_SENDER_EMAIL_DOMAIN}` || '@staging.notify.gov.sg';

export const CAMPAIGN_STATE_TEXT: { [key: string]: string } = {
    DRAFT: 'draft',
    INACTIVE: 'inactive',
    ACTIVE: 'active',
};

export const TRACKING = {
    PAGES: AppPage,
    COMPONENTS: AppComponent,
};
